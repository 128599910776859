import axios from "axios";

import { useState, useEffect } from "react";

function LocalStorage(key, initialState) {
  const [state, setState] = useState(
    () => JSON.parse(window.localStorage.getItem(key)) || initialState
  );

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
}

const printHelloWorld = () => {
  console.log('Hello Wolrd');
};

const common_url = "https://ranger.purpleacademy.co.kr/api_v2/";
const request = (url,json) =>{
  return new Promise(async (resolve)=>{
    // console.log(json)
    const reqSet = {
      method: "POST",
      headers:{
          "Content-Type": "application/json"
      },
      body:""
    }
    if(json){
        reqSet.body = JSON.stringify(json);
    }
    // console.log(reqSet);
    const response = await fetch(common_url+url, reqSet)
    const results = await response.json();
    resolve(results);
    // resolve(
    //   axios
    //     .post(   // 로그인을 위한 포스트 요청
    //       common_url+url,
    //       json,
    //       { 'Content-Type': 'application/json', withCredentials: true }
    //     ).catch((err) => alert(err))
    // );
  })
}
const request_axios = (url, json) => {
	return new Promise(async (resolve) => {
		resolve(
		  axios
		    .post(   // 로그인을 위한 포스트 요청
		      common_url+url,
		      json,
		      { 'Content-Type': 'application/json', withCredentials: true }
		    ).catch((err) => alert(err))
		);
	});
};

export { printHelloWorld, request, LocalStorage, request_axios };