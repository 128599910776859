import React, { useState, useEffect } from "react";
import {request, LocalStorage} from "../../App"

import './Login.css';

// image
import logo from '../../images/logo.png';
import loginResource1 from '../../images/login_resource1.svg';
//

function Login() {
  const [loginMsg, setloginMsg] = useState("");
  const [account, setAccount] = useState({
    id: "",
    pw: "",
  });
  const [token, setToken] = LocalStorage("token", "");

  


  const sessioncheck = async () =>{
    
    if (token !== "") {
      const result = await request("sessioncheck",{token:token})
      if(result.status===1){
        window.location.replace("/main")
        return
      }
    }
  }
  useEffect(() => {sessioncheck()}, []);

  const onChangeAccount = (e) => {
    setAccount({
      ...account,
      [e.target.name]: e.target.value,
    });
    setloginMsg("");
  };

   const handleKeyDown = (e) => {
			if (e.key === 'Enter') {
				loginAction(); // 작성한 댓글 post 요청하는 함수
			}
		};

  async function loginAction(){
    const checkStatus = await request("logincheck",{pid:account.id,birth:account.pw})
    if(checkStatus.status===0){
      setloginMsg("계정 정보가 일치하지 않습니다.");
    }else{
      const results = await request("login",{pid:account.id,birth:account.pw});
      setToken(results.token);
      window.location.replace("/main")
    }
  }

  return (
		<div className="login-background">
			<div className="login-container">
				<img className="logo" src={logo} alt="" />
				<div className="login_group">
					<input
						name="id"
						type="text"
						placeholder="원번을 입력해주세요."
						onChange={onChangeAccount}
						onKeyDown={handleKeyDown}
					/>
					<input
						name="pw"
						type="password"
						placeholder="생년월일 8자리를 입력해주세요."
						onChange={onChangeAccount}
						onKeyDown={handleKeyDown}
					/>
					<div className="info-text">
						<span id="login_result">{loginMsg}</span>
					</div>
					<button className="login-btn" onClick={() => loginAction()}>
						Go
					</button>
				</div>
			</div>

			<div className="bottom-wrapper">
				<img
					className="bottom-img"
          alt=""
          src={loginResource1}
				/>

				
			</div>
		</div>
	);
}

export default Login;
