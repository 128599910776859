import logo from './logo.svg';
import './Main.css';
import '../../App.css'
import {request, LocalStorage} from "../../App"
import Header from '../Header/Header'

import icon_aitest1 from '../../images/icon_aitest1.svg';
import icon_aitest2 from '../../images/icon_aitest2.svg';
import icon_aitest3 from '../../images/icon_aitest3.svg';
import icon_feedback from '../../images/icon_feedback.svg'

function Main() {
  const [token, setToken] = LocalStorage("token", 0);
  function onClick(){
    console.log(token);
  }
  return (
    <div className="full_wrapper main-page">
      <Header />
      <main>
        <div className="row">
          <div className="main-menu" onClick={()=>{window.location.replace('aitest')}}>
            <span className="menu-text">AI 솔루션</span>
            <div className="icon-slider-wrapper">
              <div className="icon-slider">
                <img src={icon_aitest1} alt=""/>
                <img src={icon_aitest2} alt=""/>
                <img src={icon_aitest3} alt=""/>
              </div>
            </div>
          </div>
          <div className="main-menu" onClick={()=>{window.location.replace('comments')}}>
            <span className="menu-text">코멘트 확인</span>
            <img src={icon_feedback} alt=""/>
          </div>
        </div>
        <div className="row row2">
          <div className="sub-menu" onClick={()=>{
            let openNewWindow = window.open('about:blank');
            openNewWindow.location.href =
							'https://ranger.purpleacademy.co.kr/api_v2/file/ReadingRanger Guide BETA.pdf';
          }}>
            <span className="menu-text">사용 가이드</span>
          </div>
          <div className="sub-menu" onClick={()=>{
            let imsi = 0;
            if(imsi===1){
              window.location.replace('faq');
            }
            else{
              alert("준비 중입니다.")
            }
          }}>
            <span className="menu-text">FAQ</span>
          </div>
        </div>
      </main>
  </div>
  );
}

export default Main;
