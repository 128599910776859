import logo from './logo.svg';
import './Comments.css';
import { useState, useRef, useEffect } from 'react';
import { request, LocalStorage } from '../../App';
import Header from '../Header/Header';

import btn_back from '../../images/btn_back.svg';
import btn_forward from '../../images/btn_forward.svg';
import btn_highlight from '../../images/btn_highlight.svg';
import btn_play from '../../images/btn_play.svg';
import btn_record from '../../images/btn_record.svg';
import btn_retry from '../../images/btn_retry.svg';
import btn_stop from '../../images/btn_stop.svg';
import btn_fin from '../../images/btn_fin.png';
import btn_listen from '../../images/btn_listen.svg';

import ranger_Red from '../../images/ranger_Red.svg';
import ranger_Orange from '../../images/ranger_Orange.svg';
import ranger_Yellow from '../../images/ranger_Yellow.svg';
import ranger_Green from '../../images/ranger_Green.svg';
import ranger_Blue from '../../images/ranger_Blue.svg';
import ranger_Purple from '../../images/ranger_Purple.svg';
import baby_bunny1 from '../../images/baby_bunny1.svg';
import baby_bunny2 from '../../images/baby_bunny2.svg';
import baby_bunny3 from '../../images/baby_bunny3.svg';

import wrapper_comment from '../../images/wrapper_comment.svg';
import go_to_click from '../../images/go_to_click.svg';

function Comments() {
	const [token, setToken] = LocalStorage('token', '');

	const [commentList, setCommentList] = useState([])
	const commentListRef = useRef(null);

	const modalRef = useRef(null);
	const commentID = useRef(0);
	const commentMainRef = useRef(null);
	const commentTellerRef = useRef(null);
	const [commentRangerTextState, setCommentRangerTextState] = useState();
	const agreeBodyRef = useRef(null);
	const agreeBabyRef = useRef(null);
	const clickBodyRef = useRef(null);
	const agreeWrapperRef = useRef(null);
	const clinicPagnationRef = useRef(null);

	const firstBtnRef = useRef(null);
	const recordBtnRef = useRef(null);
	const playBtnRef = useRef(null);
	const lastBtnRef = useRef(null);
	const goToClickRef = useRef(null);

	const commentsArray = useRef([]);

	const bunnyCommentTellerRef = useRef(null);
	const bunnyClinicTellerRef = useRef(null);

	const [commentStep, setCommentStep] = useState(null);
	const [commentWeek, setCommentWeek] = useState(null);
	const [commentLearningObj, setCommentLearningObj] = useState("");
	const [commentLearningResult, setCommentLearningResult] = useState("");
	const [agreeTextState, setAgreeTextState] = useState("");
	const agreeTextRef = useRef(null);
	const btnBoxRef = useRef(null);
	const commentDetailMainRef = useRef(null);

	const btnArray = [];
	const [btnBox, setBtnBox] = useState(btnArray);
	const pageArray = [];
	const [pageBox, setPageBox] = useState(pageArray);
	const pageNow = useRef(1);

	const cardArray = [];
	const [cardArrState, setCardArrState] = useState(cardArray);
	const pagnationArray = [];
	const [pagnationBox, setPagnationBox] = useState(pagnationArray);

	const [permission, setPermission] = useState(false);
	const mediaRecorder = useRef(null);
	const stream = useRef(null);
	const audioChunks = useRef([]);
	const [audio, setAudio] = useState(null);
	const mimeType = 'audio/mp4';
	const recordingAudio = useRef(null);
	const recordingStatus = useRef(0);
	const [music, setMusic] = useState(null);
	const musicRef = useRef(null);
	const recodingBackground = useRef(null);

	async function make_br(text){
		return (
		<div>
			{text.split('<br>').map((line,index) => {
			//this.props.data.content: 내용
			return (
				<span key={index}>
				{line}
				<br />
				</span>
			);
			})}
		</div>
		)
	}
	async function make_comment_teller_text(type){
		if(type===1){
			return (
				<div>
					이제 우리 퍼플 용사와 함께
					<br />
					중요한 문장을 연습해 보아요!
				</div>
			);
		}else{
			return (
				<div>
					정말 대단한걸!
					<br />
					다음주에 또 만나자!
				</div>
			);
		}
		
	}
	async function make_clinic_sentence(text){
		const br_text = await make_br(text);
		return (
				<div className='clinic-sentence' style={{ fontSize: '3.125rem',fontWeight:400, display:"flex",alignItems:"center",justifyContent:"center" }}>
					{br_text}
					<div>
						<img
							src={btn_play}
							alt=""
				style={{width:"4rem", marginLeft:"1.5rem"}}
				className='comment-clinic-play-music'
							onClick={() => {
								musicRef.current.play();
							}}
						/>
					</div>
				</div>
			);
	}
	async function make_learning_result(top, bottom, sight_word_Array, phonics_Array, middle, step_text) {
		let phonics_count = 0;
		phonics_Array.forEach(el => {
			phonics_count += el.rows.length;
		});
		let sight_word_count = 0;
		sight_word_Array.forEach(el => {
			if(el.sight_word!==""){
				sight_word_count +=1;
			}
		});

		if (phonics_count === 0 && sight_word_count === 0) {
			console.log(step_text);
			if(step_text==="레드"||step_text==="오렌지"||step_text==="옐로우"){
				return (
					<div>
						<div
							className="comment-detail-section2-title"
							style={{ marginRight: '1rem' }}
						>
							나의 학습결과
						</div>
						<div style={{ marginTop: '1rem' }}>{top}</div>
						<div style={{ marginTop: '1rem' }}>
							이번 주차에서 꼭! 알고 넘어가야 하는 파닉스 및 사이트워드를
						</div>
						<div style={{ marginTop: '1rem' }}>
							모두잘 읽어주었어요. 한주동안 열심히 리딩하고
						</div>
						<div style={{ marginTop: '1rem' }}>다음주차 인증미션 때 만나요~</div>
					</div>
				);
			}else{
				return (
					<div>
						<div
							className="comment-detail-section2-title"
							style={{ marginRight: '1rem' }}
						>
							나의 학습결과
						</div>
						<div style={{ marginTop: '1rem' }}>{top}</div>
						<div style={{ marginTop: '1rem' }}>
							이번 주차에서 학습한 파닉스 및 사이트워드를 모두 잘 읽어주었어요.
						</div>
						<div style={{ marginTop: '1rem' }}>
							{middle
								.replace('위주로 연습한다면 ', '이렇게 열심히 한다면 ' )
								.replace(' 통과할 수 있어요!', '')}
						</div>
						<div style={{ marginTop: '1rem' }}>통과할 수 있어요!</div>
					</div>
				);
			}
		} else {
			// console.log(sight_word_Array);
			// console.log(phonics_Array);
			return (
				<div>
					<div
						className="comment-detail-section2-title"
						style={{ marginRight: '1rem' }}
					>
						나의 학습결과
					</div>
					<span>{top}</span>

					<div
						className="comment-table-cover"
						style={{
							display: 'flex',
							width: '90%',
							marginTop: '1rem',
							padding: '0 2.5rem',
						}}
					>
						{phonics_count !== 0 ? (
							<div
								style={{
									width: '30%',
									textAlign: 'center',
									marginLeft: '2.5rem',
								}}
							>
								<div>
									<div
										className="comment-result-table-title"
										style={{ color: '#F44336' }}
									>
										파닉스
									</div>
								</div>
								<table
									className="comment-result-table"
									style={{ width: '100%' }}
								>
									<tbody>
										{phonics_Array.map((element, index) => {
											if (element.rows.length > 0) {
												return (
													<tr key={index}>
														<td style={{ width: '40%' }}>{element.phonics}</td>
														<td style={{ width: '60%' }}>
															{element.rows.sort().join(', ')}
														</td>
													</tr>
												);
											}
										})}
									</tbody>
								</table>
							</div>
						) : null}
						{sight_word_count !== 0 ? (
							<div
								style={{
									width: '30%',
									textAlign: 'center',
									marginLeft: '2.5rem',
								}}
							>
								<div>
									<div
										className="comment-result-table-title"
										style={{ color: '#4FAFFF' }}
									>
										사이트워드
									</div>
								</div>
								<table className="comment-result-table">
									<tbody>
										{sight_word_Array.map((element, index) => {
											return (
												<tr key={index}>
													<td>{element.sight_word}</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						) : null}
					</div>

					<div style={{ marginTop: '1rem' }}>{middle}</div>

					<div className="comment-detail-body-bottom" style={{ width: '70%' }}>{bottom}</div>
				</div>
			);
		}
			
		}

	const checkMicrophonePermission = async () => {
			try {
				const streamData = await navigator.mediaDevices.getUserMedia({
					audio: true,
					video: false,
				});
				setPermission(true);
				stream.current = streamData;
		return true;
			} catch (err) {
				console.log(err);
				console.log('음성 기능을 사용할 수 없습니다.');
		return false;
			}
		};
	const startRecording = async () => {
			return new Promise((resolve, reject) => {
				//create new Media recorder instance using the stream
				const media = new MediaRecorder(stream.current, { type: mimeType });
				//set the MediaRecorder instance to the mediaRecorder ref
				mediaRecorder.current = media;
				//invokes the start method to start the recording process
				mediaRecorder.current.start();
				mediaRecorder.current.ondataavailable = (event) => {
					if (typeof event.data === 'undefined') {
						return;
					}
					if (event.data.size === 0) {
						return;
					}
					audioChunks.current = [event.data];
				};
				resolve(1);
			});
		};
	const stopRecording = () => {
		return new Promise((resolve, reject) => {
			//stops the recording instance
			mediaRecorder.current.stop();
			mediaRecorder.current.onstop = () => {
				//creates a blob file from the audiochunks data
				const audioBlob = new Blob(audioChunks.current, { type: mimeType });
				//creates a playable URL from the blob file.
				const audioUrl = URL.createObjectURL(audioBlob);
				setAudio(audioUrl);
				audioChunks.current = [];
		recordingStatus.current =1;

				resolve(audioBlob);
			};
		});
	};
	const startTestRecording = async (page) => {
		firstBtnRef.current.style.opacity = 0.3;
		firstBtnRef.current.style.pointerEvents = 'none';
		playBtnRef.current.style.opacity = 0.3;
		playBtnRef.current.style.pointerEvents = 'none';
		lastBtnRef.current.style.opacity = 0.3;
		lastBtnRef.current.style.pointerEvents = 'none';
		recodingBackground.current.style.display = 'block';
		setBtnBox(btnArray);
		setBtnBox((current) => [
			...current,
			{
				img_source: btn_back,
				func: setClinicView,
				text: '뒤로가기',
				ref: firstBtnRef,
			},
		]);
		setBtnBox((current) => [
			...current,
			{
				img_source: btn_stop,
				func: stopTestRecording,
				text: '멈추기',
		variable:page,
				ref: recordBtnRef,
			},
		]);
		setBtnBox((current) => [
			...current,
			{
				img_source: btn_listen,
				func: setClinicView,
				text: '듣기',
				ref: playBtnRef,
			},
		]);
		if (page === commentsArray.current.clinic.length - 1) {
			setBtnBox((current) => [
				...current,
				{
					img_source: btn_fin,
					func: finish_clinic,
					text: '완료하기',
					ref: lastBtnRef,
				},
			]);
		} else {
			setBtnBox((current) => [
				...current,
				{
					img_source: btn_forward,
					func: setClinicView,
					text: '다음으로',
					variable: page + 1,
					ref: lastBtnRef,
				},
			]);
		}
		await startRecording();
	};
	const stopTestRecording = async (page) => {
		firstBtnRef.current.style.opacity = 1;
		firstBtnRef.current.style.pointerEvents = 'auto';
		playBtnRef.current.style.opacity = 1;
		playBtnRef.current.style.pointerEvents = 'auto';
		lastBtnRef.current.style.opacity = 1;
		lastBtnRef.current.style.pointerEvents = 'auto';
		recodingBackground.current.style.display = 'none';
		setBtnBox(btnArray);
		setBtnBox((current) => [
			...current,
			{
				img_source: btn_back,
				func: setClinicView,
				text: '뒤로가기',
				variable: page - 1,
				ref: firstBtnRef,
			},
		]);
		setBtnBox((current) => [
			...current,
			{
				img_source: btn_record,
				func: startTestRecording,
				text: '말하기',
				variable: page,
				ref: recordBtnRef,
			},
		]);
		setBtnBox((current) => [
			...current,
			{
				img_source: btn_listen,
				func: listen_voice,
				text: '듣기',
				ref: playBtnRef,
			},
		]);
		if (page === commentsArray.current.clinic.length - 1) {
			setBtnBox((current) => [
				...current,
				{
					img_source: btn_fin,
					func: finish_clinic,
					variable: page,
					text: '완료하기',
					ref: lastBtnRef,
				},
			]);
		} else {
			setBtnBox((current) => [
				...current,
				{
					img_source: btn_forward,
					func: setClinicView,
					text: '다음으로',
					variable: page + 1,
					ref: lastBtnRef,
				},
			]);
		}
		await stopRecording();
	const update_clinic_result = await request('update_clinic', {
			clinic_log_id: commentsArray.current.clinic[page].id,
		});
	commentsArray.current.clinic[page].status = 1;
	console.log(update_clinic_result);
	};

	async function listen_voice(){
		if(recordingStatus.current === 1){
		recordingAudio.current.play();
		}else{
		alert("말하기를 먼저 진행해주세요.")
		}
	}

	async function finish_clinic(page){
		setPagnationBox(pagnationArray);
		const mment_list = [
			`우와~ 리딩특공대 ${commentStep}용사와 함께 클리닉을 <br>정말 잘 진행해주었어~ 우리 일주일동안 <br>또 열심히 리딩해서 다음주에 또 만나자! `,
			`대단해~ 이번 주차 리딩 미션과 클리닉을 모두 완료했어!<br>다음 주도 열심히 리딩해서 ${commentStep}용사 찾아와 줄거지?  `,
			`정말 잘했어! 중요하게 학습해야할 문장/단어를 <br>모두 잘 연습해주었어~ <br>성장한 미래의 리특 용사를 기다리고 있을게~ <br>다음 주에 또 보자!`,
			`대단해! 클리닉을 통해 한 단계 성장한 모습이 <br>정말 자랑스러워! 우리 일주일동안 열심히 리딩해서 <br>${commentStep}용사와 만나자~`,
			`와~ 이번 주에도 열심히 리딩해서 리딩 미션과 <br>클리닉까지 완료했어! 이렇게만 한다면 미래의 <br>리특 용사가 되는 건 시간 문제일거야!`,
		]; //14
	const random_num = Math.floor(Math.random() * 5);
	const mment = mment_list[random_num];

	const agree_text = await make_br(mment);

	setAgreeTextState(agree_text);

	setBtnBox(btnArray);
		setBtnBox((current) => [
			...current,
			{
				img_source: btn_fin,
				text: '끝내기',
		func:closeModal
			},
		]);
	commentID.current = 0;
}


	async function changePage(page) {
		if(page === pageNow.current) {
			return;
		}

		pageNow.current = page;
		console.log(page);
		console.log(commentListRef.current);
		console.log(commentListRef.current.slice(8 * (page - 1), 8 * page));
		setCommentList(commentListRef.current);
		setCardArrState(commentListRef.current.slice(8 * (page - 1), 8 * page));

		setPageBox(pageArray);
		for (let i = 0; i < Math.ceil(commentListRef.current.length / 8); i++) {
			let page_class_name = '';
			if (i === page - 1) {
				page_class_name = 'comments-pagination-page-selected';
			} else {
				page_class_name = 'comments-pagination-page';
			}
			setPageBox((current) => [
				...current,
				{
					class_name: page_class_name,
					func: changePage,
					variable: i + 1,
				},
			]);
		}
	}
	async function getComments() {
		const comments = await request('get_comments', { token: token });
		setCommentList(comments);
		commentListRef.current = comments;
		console.log(commentListRef.current);
		setCardArrState(comments.slice(0,8));

		setPageBox(pageArray);
		for(let i = 0; i < Math.ceil(comments.length/8); i++) {
			let page_class_name = "";
			if(i===0){
				page_class_name = 'comments-pagination-page-selected';
			}else{
				page_class_name = 'comments-pagination-page';
			}
			setPageBox((current) => [
				...current,
				{
					class_name: page_class_name,
					func: changePage,
					variable: i + 1,
				},
			]);
		}
		

		// console.log(comments);
	}

	async function viewModal(comments_id) {
		if (comments_id !== commentID.current) {
			console.log(comments_id);
			setCommentView(comments_id);
			commentID.current = comments_id;
		}
		modalRef.current.style.display = 'block';
	}
	async function closeModal() {
		modalRef.current.style.display = 'none';
	}

	async function setCommentView(comments_id) {
		commentsArray.current = await request('get_comment_log',{week_log_id:comments_id});
		// console.log(commentsArray.current);
		setCommentStep(commentsArray.current.comment[0].week_text_kor);
		const week_text_kor = commentsArray.current.comment[0].week_text_kor;
		console.log(week_text_kor);

		if (week_text_kor === '레드') {
			bunnyCommentTellerRef.current.src = ranger_Red;
			bunnyClinicTellerRef.current.src = ranger_Red;
		} else if (week_text_kor === '오렌지') {
			bunnyCommentTellerRef.current.src = ranger_Orange;
			bunnyClinicTellerRef.current.src = ranger_Orange;
		} else if (week_text_kor === '옐로우') {
			bunnyCommentTellerRef.current.src = ranger_Yellow;
			bunnyClinicTellerRef.current.src = ranger_Yellow;
		} else if (week_text_kor === '그린') {
			bunnyCommentTellerRef.current.src = ranger_Green;
			bunnyClinicTellerRef.current.src = ranger_Green;
		} else if (week_text_kor === '블루') {
			bunnyCommentTellerRef.current.src = ranger_Blue;
			bunnyClinicTellerRef.current.src = ranger_Blue;
		} else if (week_text_kor === '퍼플') {
			bunnyCommentTellerRef.current.src = ranger_Purple;
			bunnyClinicTellerRef.current.src = ranger_Purple;
		}

		setCommentWeek(commentsArray.current.comment[0].week_on_this_step);
		const learning_obj = await make_br(commentsArray.current.comment[0].learning_obj);
		setCommentLearningObj(learning_obj);


		const learning_result_top = commentsArray.current.comment[0].learning_result_top;
		const learning_result_bottom =commentsArray.current.comment[0].learning_result_bottom;
		const learning_obj_sight_word=commentsArray.current.comment[0].learning_obj_sight_word;
		const learning_obj_phonics = commentsArray.current.comment[0].learning_obj_phonics;
		const learning_result = commentsArray.current.comment[0].learning_result;

		const phonics_sort_array = commentsArray.current.comment[0].learning_obj
			.split('<br>')[0]
			.replace("파닉스 : ","")
			.split(', ').map(function(el){
				return {phonics : el.split("(")[0], rows:[]}
			});
		const sight_word_sort_array =
			learning_obj_sight_word
			.split(', ')
			.sort()
			.map(function (el) {
				return { sight_word: el.split('(')[0], rows: [] };
			});
		
		if (
			learning_obj_phonics.split(", ")[0] === "" &&
			learning_obj_sight_word.split(', ')[0] === ""
		) {
			const comment_ranger_text = await make_comment_teller_text(0);
			setCommentRangerTextState(comment_ranger_text);
		} else {
			const comment_ranger_text = await make_comment_teller_text(1);

			setCommentRangerTextState(comment_ranger_text);
		}


		learning_obj_phonics.split(", ").map(function(el){
			const phonics = el.split(" ")[0];
			const letters = el.split(" ")[1];
			for(let i=0; i<phonics_sort_array.length; i++){
				if(phonics_sort_array[i].phonics===phonics){
					phonics_sort_array[i].rows.push(letters)
					phonics_sort_array[i].rows = [...new Set(phonics_sort_array[i].rows)];

					return;
				}
			}
		})



		const learning_result_text = await make_learning_result(
			learning_result_top,
			learning_result_bottom,
			sight_word_sort_array,
			phonics_sort_array,
			learning_result,
			week_text_kor
		);//await make_br(commentsArray.current.comment[0].learning_result);

		setCommentLearningResult(learning_result_text);

		// console.log(commentsArray.current.comment[0].learning_result_top);

		commentMainRef.current.style.display = 'block';
		commentTellerRef.current.style.display = 'initial';
		agreeBodyRef.current.style.display = 'none';
		agreeBabyRef.current.style.display = 'none';

		const babybunnyRandNum = Math.floor(Math.random() * 3);
		if (babybunnyRandNum===1){
			agreeBabyRef.current.src = baby_bunny1;
		}else if(babybunnyRandNum===2){
			agreeBabyRef.current.src = baby_bunny2;
		}else{
			agreeBabyRef.current.src = baby_bunny3;
		}

		clickBodyRef.current.style.display = 'none';
		btnBoxRef.current.style.display = 'none';
		goToClickRef.current.style.display = 'initial';
		commentDetailMainRef.current.style.width = "80%"

		if(commentsArray.current.clinic.length===0){
		goToClickRef.current.style.display = 'none';
		}
	}

	async function setAgreeView() {
	setPagnationBox(pagnationArray);
	commentMainRef.current.style.display = 'none';
	commentTellerRef.current.style.display = 'none';
	agreeBodyRef.current.style.display = 'block';
	agreeBabyRef.current.style.display = 'block';
	agreeWrapperRef.current.style.display = "flex";
	btnBoxRef.current.style.display = "none";
	clinicPagnationRef.current.style.display = "none";
	goToClickRef.current.style.display = 'none';
	commentDetailMainRef.current.style.width = 'initial';

	

	let sentOrword = ""
	if (commentStep === '레드' || commentStep === '오렌지'||commentStep==="옐로우") {
	sentOrword = "단어"
		} else {
	sentOrword = '문장';
		}
	const mment_list = [
			`안녕~ 나는 리딩특공대 ${commentStep}용사야!<br>오늘 클리닉에서 연습해야 할 ${sentOrword}들을 <br>열심히 듣고 따라 읽어보자!`,
			`안녕 친구들~ 나는 리딩특공대 ${commentStep}용사야!<br>오늘 중요하게 연습해볼 ${sentOrword}을 내가 읽어줄테니<br> 잘~ 듣고 따라읽어봐!`,
			`안녕! 퍼플친구들~ 나는 리딩특공대 ${commentStep}용사야!<br>오늘의 중요 ${sentOrword}을 연습해 볼 시간이야! <br>자~ 잘 듣고 따라 읽어보자!`,
			`안녕 얘들아~ 나는 리딩특공대 ${commentStep}용사야!<br>내가 중요한 ${sentOrword}을 읽어줄테니<br> 잘 듣고 정확한 발음으로 따라읽으면서 연습해보자!`,
			`안녕 미래의 리특용사들~ 나는 리딩특공대 ${commentStep}용사야!<br>오늘 클리닉에서 연습해야할 ${sentOrword}을 잘 듣고 따라 읽으며<br> 정확한 발음을 가진 리특용사가 되어보자!`,
		]; //14
	const random_num = Math.floor(Math.random() * 5);
	const mment = mment_list[random_num];

	const agree_text = await make_br(
			mment
		);

	setAgreeTextState(agree_text)

	setBtnBox(btnArray);
		setBtnBox((current) => [
			...current,
			{
				img_source: btn_back,
				text: '뒤로가기',
				ref: firstBtnRef,
			},
		]);
	setBtnBox((current) => [
			...current,
			{
				img_source: btn_record,
				text: '말하기',
				ref: recordBtnRef,
			},
		]);
	setBtnBox((current) => [
			...current,
			{
				img_source: btn_play,
				text: '듣기',
				ref: playBtnRef,
			},
		]);
	setBtnBox((current) => [
			...current,
			{
				img_source: btn_forward,
				text: '다음으로',
				ref: lastBtnRef,
			},
		]);
	
	}

	async function setClinicView(page) {
	if(page===-1){
	setAgreeView();
	return;
	}
	const permission = checkMicrophonePermission();
	if(!permission){
	alert("음성 기능을 사용할 수 없습니다.")
	return;
	}

	setPagnationBox(pagnationArray);
		for (let i = 0; i < commentsArray.current.clinic.length; i++) {
			if (i === page) {
				setPagnationBox((current) => [
					...current,
					{
						class_name: 'console-page console-page-selected',
						value: page + 1,
					},
				]);
			} else {
				setPagnationBox((current) => [
					...current,
					{ class_name: 'console-page', value: '' },
				]);
			}
		}

	agreeWrapperRef.current.style.display = "none";
	btnBoxRef.current.style.display = 'flex';
		clinicPagnationRef.current.style.display = 'flex';

	setMusic(
			'https://ranger.purpleacademy.co.kr/api/sound/' +
				commentsArray.current.clinic[page].music
		);
	const clinic_text = await make_clinic_sentence(
			commentsArray.current.clinic
		[page].sentence);
	setAgreeTextState(clinic_text)

	setBtnBox(btnArray);
	setBtnBox((current) => [
			...current,
			{
				img_source: btn_back,
				func: setClinicView,
				text: '뒤로가기',
				variable: page - 1,
				ref: firstBtnRef,
			},
		]);
	setBtnBox((current) => [
			...current,
			{
				img_source: btn_record,
				func: startTestRecording,
				text: '말하기',
				variable: page,
				ref: recordBtnRef,
			},
		]);
	setBtnBox((current) => [
			...current,
			{
				img_source: btn_listen,
				func: listen_voice,
				text: '듣기',
				ref: playBtnRef,
			},
		]);
	if (page===commentsArray.current.clinic.length-1){
	setBtnBox((current) => [
				...current,
				{
					img_source: btn_fin,
					func: finish_clinic,
					variable: page,
					text: '완료하기',
					ref: lastBtnRef,
				},
			]);
	}else{
	setBtnBox((current) => [
				...current,
				{
					img_source: btn_forward,
					func: setClinicView,
					text: '다음으로',
					variable: page + 1,
					ref: lastBtnRef,
				},
			]);
	}

	if(commentsArray.current.clinic[page].status===0){
	lastBtnRef.current.style.opacity = 0.3;
			lastBtnRef.current.style.pointerEvents = 'none';
	}

	playBtnRef.current.style.opacity = 0.3;
	playBtnRef.current.style.pointerEvents = "none";
	recordingStatus.current = 0;

}

	useEffect(() => {
		getComments();
	}, []);

	return (
		<div className="comments-App">
			<div className="full_wrapper">
				<Header />
				<div className="comments-main">
					<div className="comments-main-header">
						<div className="comments-main-title">코멘트 확인</div>
						<div className="comments-dropdown">
							<div className="comments-dropdown-text">전체보기</div>
							<div className="comments-dropdown-arrow">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
								>
									<path
										d="M15.5858 9H8.41421C7.52331 9 7.07714 10.0771 7.70711 10.7071L11.2929 14.2929C11.6834 14.6834 12.3166 14.6834 12.7071 14.2929L16.2929 10.7071C16.9229 10.0771 16.4767 9 15.5858 9Z"
										fill="#333333"
									/>
								</svg>
							</div>
						</div>
					</div>
					<div className="comments-main-body">
						{cardArrState.map((element, index) => {
							if (element.comment === 1) {
								return (
									<div className="comments-card-wrapper" key={index}>
										<div className={element.step + ' comments-card-top'}>
											<img
												className="comments-card-top-img"
												src={
													'https://ranger.purpleacademy.co.kr/api/week_images/' +
													element.img_name
												}
												alt=""
											/>
										</div>
										<div className="comments-card-bottom">
											<div className="comments-card-week">
												{element.step_week_text + '주차'}
											</div>
											<div className="comments-card-submitDate">
												{element.regist_date.substr(0, 10) + ' 제출 완료'}
											</div>
											<div className="comments-card-btn-wrapper">
												<button
													className="comments-card-viewFeedback"
													onClick={() => viewModal(element.id)}
												>
													피드백 확인
												</button>
											</div>
										</div>
									</div>
								);
							} else {
								return (
									<div className="comments-card-wrapper" key={index}>
										<div className={element.step + ' comments-card-top'}>
											<img
												className="comments-card-top-img"
												src={
													'https://ranger.purpleacademy.co.kr/api/week_images/' +
													element.img_name
												}
												alt=""
											/>
										</div>
										<div className="comments-card-bottom">
											<div className="comments-card-week">
												{element.step_week_text + '주차'}
											</div>
											<div className="comments-card-submitDate">
												{element.regist_date.substr(0, 10) + ' 제출 완료'}
											</div>
											<div className="comments-card-btn-wrapper">
												<button className="comments-card-viewFeedback-before">
													피드백 진행중
												</button>
											</div>
										</div>
									</div>
								);
							}
						})}
					</div>
					<div className="comments-main-footer">
						<div className="comments-pagination">
							<div className="comments-pagination-page-many-left">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
								>
									<path
										d="M11 18L5 12L11 6M19 18L13 12L19 6"
										stroke="black"
										strokeOpacity="0.4"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</div>
							<div className="comments-pagination-page-left">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
								>
									<path
										d="M15 6L9 12L15 18"
										stroke="black"
										strokeOpacity="0.4"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</div>
							{pageBox.map((element, index) => {
								return (
									<div
										className={element.class_name}
										onClick={() => element.func(element.variable)}
										key={index}
									>
										{element.variable}
									</div>
								);
							})}
							<div className="comments-pagination-page-right">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
								>
									<path
										d="M9 18L15 12L9 6"
										stroke="black"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</div>
							<div className="comments-pagination-page-many-right">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
								>
									<path
										d="M13 6L19 12L13 18M5 6L11 12L5 18"
										stroke="black"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="comments-modal" ref={modalRef}>
				<div className="wrapper-comment-wrapper">
					<svg
						className="comment-icon-close"
						onClick={() => closeModal()}
						xmlns="http://www.w3.org/2000/svg"
						width="68"
						height="68"
						viewBox="0 0 68 68"
						fill="none"
					>
						<circle cx="36" cy="36" r="32" fill="#313131" />
						<circle cx="32" cy="32" r="32" fill="#565656" />
						<rect
							x="41.9048"
							y="16"
							width="8.61994"
							height="36.6347"
							rx="4.30997"
							transform="rotate(45 41.9048 16)"
							fill="white"
						/>
						<rect
							x="16"
							y="22.0951"
							width="8.61994"
							height="36.6347"
							rx="4.30997"
							transform="rotate(-45 16 22.0951)"
							fill="white"
						/>
					</svg>
					<div className="comment-detail-main" ref={commentDetailMainRef}>
						<div className="comment-body" ref={commentMainRef}>
							<div className="comment-detail-section1">
								<div className="comment-detail-section1-title">
									{commentStep} {commentWeek}주차 인증미션 학습목표
								</div>
								<div className="comment-detail-section1-info">
									<div className="comment-detail-section1-info-textarea">
										{commentLearningObj}
									</div>
								</div>
							</div>
							<div className="comment-detail-section2">
								<div className="comment-detail-section2-info">
									<div className="comment-detail-section2-info-textarea">
										{commentLearningResult}
									</div>
								</div>
							</div>
						</div>

						<div className="agree-body" ref={agreeBodyRef}>
							<div className="console-pagnation" ref={clinicPagnationRef}>
								{pagnationBox.map((element, index) => {
									return (
										<div className={element.class_name} key={index}>
											{element.value}
										</div>
									);
								})}
							</div>
							<div className="agree-text" ref={agreeTextRef}>
								{agreeTextState}
								<img className="agree-ranger" src={ranger_Orange} alt="" ref={bunnyClinicTellerRef}/>
							</div>
							<div className="agree-btn-wraaper" ref={agreeWrapperRef}>
								<div
									className="agree-btn"
									onClick={() => setCommentView(commentID.current)}
								>
									<div className="agree-btn-text">뒤로가기</div>
								</div>
								<div className="agree-btn" onClick={() => setClinicView(0)}>
									<div className="agree-btn-text">알겠어!</div>
								</div>
							</div>
						</div>

						<div className="clinic-body" ref={clickBodyRef}></div>
					</div>
					<div className="comment-detail-ranger-wrapper" ref={commentTellerRef}>
						<div className="comment-ranger-text">{commentRangerTextState}</div>
						<img
							className="comment-readingranger"
							alt=""
							height="220px"
							ref={bunnyCommentTellerRef}
							src={ranger_Orange}
						/>
					</div>
					<img
						className="recording-background-comment"
						src={btn_highlight}
						ref={recodingBackground}
						alt=""
					/>
					<img
						className="go-to-click"
						src={go_to_click}
						alt=""
						ref={goToClickRef}
						onClick={() => setAgreeView()}
					/>
					<div className="console-btn-box" ref={btnBoxRef}>
						{btnBox.map((element, index) => {
							return (
								<div
									key={index}
									className="btn-set"
									style={{ textAlign: 'center' }}
								>
									<div className="btn-img-wrapper">
										<img
											src={element.img_source}
											alt=""
											ref={element.ref}
											onClick={() => element.func(element.variable)}
										/>
									</div>
									<div className="btn-name">{element.text}</div>
								</div>
							);
						})}
					</div>
					<div className="audio-container">
						<audio
							className="record-audio"
							src={audio}
							autoPlay
							controls
							ref={recordingAudio}
						></audio>
					</div>

					<img
						className="comment-page-comment-modal-wrapper"
						src={wrapper_comment}
						alt=""
					/>
					<img
						className="agree-baby"
						ref={agreeBabyRef}
						src={baby_bunny1}
						alt=""
					/>
				</div>
				<audio
					src={music}
					ref={musicRef}
					volume="1"
					style={{ display: 'none' }}
				></audio>
			</div>
		</div>
	);
}

export default Comments;
