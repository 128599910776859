import logo from './logo.svg';
import notice_back from './images/notice_background.jpg';
import './ErrorPage.css';

function ErrorPage() {
	let agent = navigator.userAgent.toLowerCase();
	let access_allow = 0;
	// alert(agent);
	let browserName;
	if (navigator.userAgent.match(/iPhone|iPad|iPod/i) != null) {
		if (agent.indexOf('crios') == -1) {
			browserName = 'Safari';
		} else {
			browserName = 'Chrome';
		}
	}else{
		switch (true) {
			case agent.indexOf('edge') > -1:
				browserName = 'MS Edge'; // MS 엣지
				break;
			case agent.indexOf('edg/') > -1:
				browserName = 'Edge (chromium based)'; // 크롬 기반 엣지
				break;
			case agent.indexOf('opr') > -1 && !!window.opr:
				browserName = 'Opera'; // 오페라
				break;
			case agent.indexOf('samsung') > -1:
				browserName = 'Samsung'; // 오페라
				break;
			case agent.indexOf('chrome') > -1 && !!window.chrome:
				browserName = 'Chrome'; // 크롬
				break;
			case agent.indexOf('trident') > -1:
				browserName = 'MS IE'; // 익스플로러
				break;
			case agent.indexOf('firefox') > -1:
				browserName = 'Mozilla Firefox'; // 파이어 폭스
				break;
			case agent.indexOf('safari') > -1:
				browserName = 'Safari'; // 사파리
				break;
			default:
				browserName = 'other'; // 기타
		}
	}
	return (
		<div className="App">
			
			<div className="Error-Page-Main-wrapper">
				<div className="Error-Page-Main">
					<div className="Error-section-back">
						<div className="Error-Content">
							<div className="Error-line-center">
								<img src={notice_back} alt="" />
							</div>
							<div className="Error-line-real"></div>
							<div className="Error-title">크롬 접속 권장 안내</div>
							<div className="Error-line">안녕하세요. 퍼플아카데미입니다.</div>
							<div className="Error-line">
								리딩특공대 AI 솔루션은 <b>크롬</b>을 기반으로 설계되었습니다.
							</div>
							<div className="Error-line">
								다른 브라우저에서 이용하시는 경우,
							</div>
							<div className="Error-line">
								원활한 서비스 제공이 되지 않아 <b>크롬</b>으로 이용하시기를 권장
								드립니다.
							</div>
							<div className="Error-line">이용에 불편을 드려 죄송합니다.</div>
							<div className="Error-line">감사합니다.</div>
							<div className="Error-Info">
								현재 접속 브라우저 : {browserName}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ErrorPage;
