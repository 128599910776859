import logo_horizontal from '../../images/logo_horizontal.png';
import user_icon from '../../images/user_icon.png';
import {request, LocalStorage, request_axios} from "../../App"
import { useState, useEffect } from 'react';

const Header = () =>{
    const [token, setToken] = LocalStorage("token", "");
    const [step, setStep] = useState("");
    const [name, setName] = useState("");
    // const sessioncheck = async () =>{
    //     const result = await request("sessioncheck",{token:token})
    //     if(result.status !== 0){
    //         if (result.data.rows.length === 1) {
    //             setName(result.data.rows[0].name);
    //             setStep(result.data.rows[0].step);
    //         } else {
    //             window.location.replace('/login');
    //         }
    //     }else{
	// 		alert("오류가 발생했습니다. 잠시 후에 다시 시도해주세요.");
    //         window.location.replace('/login');
    //     }
        
    //     // request_axios("session");
    // }
    // useEffect(() => {sessioncheck()});

    async function logout(){
        const status = await request("logout",{token:token})
        if(status.status===1){
            setToken("")
            window.location.replace("login")
        }
    }

    return (
			<header>
				<div>
					<img
						src={logo_horizontal}
						style={{width:"203px"}}
						onClick={() => {
							window.location.replace('main');
						}}
					/>
				</div>
				<div className="user-icon">
					<img
						src={user_icon}
						onClick={() => {
							console.log(token);
						}}
					/>
				</div>
				<div className="sideBar">
					<div className="user-info-wrapper">
						<div className="user-info">
							<img src={user_icon} />
							<div className="weekNuser-wrapper">
								<div className="week-info">{step}</div>
								<div className="user-name">{name}</div>
							</div>
						</div>
					</div>
					<div></div>
					<div
						className="sideBar-menu sideBar-top"
						onClick={() => {
							window.location.replace('aitest');
						}}
					>
						AI 솔루션
					</div>
					<div
						className="sideBar-menu"
						onClick={() => {
							window.location.replace('comments');
						}}
					>
						코멘트 확인
					</div>
					<div
						className="sideBar-menu"
						onClick={() => {
							let openNewWindow = window.open('about:blank');
							openNewWindow.location.href =
								'https://ranger.purpleacademy.co.kr/api/file/ReadingRanger Guide BETA.pdf';
						}}
					>
						사용 가이드
					</div>
					<div
						className="sideBar-menu"
						onClick={() => {
							let imsi = 0;
							if (imsi === 1) {
								window.location.replace('faq');
							} else {
								alert('준비 중입니다.');
							}
						}}
					>
						FAQ
					</div>
					<div className="sideBar-menu" onClick={() => logout()}>
						로그아웃
					</div>
				</div>
			</header>
		);
}
export default Header