import React,{useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import {CookiesProvider} from 'react-cookie';
import {request, LocalStorage} from "./App"

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';

import './index.css';
// pages
import AItest from './pages/AItest/AItest';
import Comments from './pages/Comments/Comments';
import FAQ from './pages/FAQ/FAQ';
import Guide from './pages/Guide/Guide';
import Login from './pages/Login/Login';
import Main from './pages/Main/Main';
import ErrorPage from './ErrorPage.js';

const router = createBrowserRouter([
  {
    path: "/comments",
    element: <Comments />,
  },
  {
    path: "/aitest",
    element: <AItest />,
  },
  {
    path: "/faq",
    element: <FAQ />,
  },
  {
    path: "/guide",
    element: <Guide />,
  },
  {
    path: "/main",
    element: <Main />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/*",
    element: <Login />,
  },
]);

let agent = navigator.userAgent.toLowerCase();
let access_allow = 0;
// alert(agent);
if (navigator.userAgent.match(/iPhone|iPad|iPod/i) != null) {
	if (agent.indexOf('crios') == -1) {
		console.log("not chrome");
	} else {
    access_allow = 1;
	}
}else{
  // alert(agent)
  switch (true) {
		case agent.indexOf('edge') > -1:
			// MS 엣지
			break;
		case agent.indexOf('edg/') > -1:
			 // 크롬 기반 엣지
			break;
		case agent.indexOf('opr') > -1 && !!window.opr:
			// 오페라
			break;
    case agent.indexOf('samsung') > -1:
      //삼성인터넷
      break;
		case agent.indexOf('chrome') > -1 && !!window.chrome:
			// 크롬
      access_allow = 1;
			break;
		case agent.indexOf('trident') > -1:
			 // 익스플로러
			break;
		case agent.indexOf('firefox') > -1:
			// 파이어 폭스
			break;
		case agent.indexOf('safari') > -1:
			// 사파리
			break;
		default:
			 // 기타
	}
}
const root = ReactDOM.createRoot(document.getElementById('root'));
if(access_allow===1){
	root.render(
		<CookiesProvider>
			<RouterProvider router={router} />
		</CookiesProvider>
	);
}else{
  alert('리딩특공대 AI 솔루션은 크롬으로 이용 가능합니다.');
  root.render(
    <ErrorPage />
  );
}


reportWebVitals();
