import logo from './logo.svg';
import '../../App.css';
import './AItest.css';
import { request, LocalStorage } from '../../App';
import Header from '../Header/Header';

import btn_back from '../../images/btn_back.svg';
import btn_forward from '../../images/btn_forward.svg';
import btn_highlight from '../../images/btn_highlight.svg';
import btn_play from '../../images/btn_play.svg';
import btn_record from '../../images/btn_record.svg';
import btn_retry from '../../images/btn_retry.svg';
import btn_stop from '../../images/btn_stop.svg';
import btn_fin from '../../images/btn_fin.png';
import { useState, useRef, useEffect } from 'react';

import ranger_Red from '../../images/ranger_Red.svg';
import ranger_Orange from '../../images/ranger_Orange.svg';
import ranger_Yellow from '../../images/ranger_Yellow.svg';
import ranger_Green from '../../images/ranger_Green.svg';
import ranger_Blue from '../../images/ranger_Blue.svg';
import ranger_original from '../../images/ranger.svg';

function AItest() {
	const [token, setToken] = LocalStorage('token', '');
	const [weekLogId, setWeekLogId] = useState(0);

	const [coverFillColor, setCoverFillColor] = useState('none');
	const consoleCover = useRef(null);
	const [countdownNum, setCountdownNum] = useState(3);
	const countdownNumRef = useRef(null);
	const spinnerRef = useRef(null);
	const [spinnerText, setSpinnerText] = useState('');
	const pagnationRef = useRef(null);
	const mainTextRef = useRef(null);
	const [mainText, setMainText] = useState('');
	const [subText, setSubText] = useState('');
	const btnBoxRef = useRef(null);

	const btnArray = [];
	const [btnBox, setBtnBox] = useState(btnArray);

	const pagnationArray = [];
	const [pagnationBox, setPagnationBox] = useState(pagnationArray);

	const [permission, setPermission] = useState(false);
	const mediaRecorder = useRef(null);
	const stream = useRef(null);
	const audioChunks = useRef([]);
	const [audio, setAudio] = useState(null);
	const mimeType = 'audio/webm';
	const [music, setMusic] = useState(null);
	const musicRef = useRef(null);
	const firstBtnRef = useRef(null);
	const playBtnRef = useRef(null);
	const lastBtnRef = useRef(null);
	const mainRecordFuncStatus = useRef(0);

	const permissionBtnBox = useRef(null);
	const testRecordingBtnBox = useRef(null);
	const afterTestBtnBox = useRef(null);
	const recordingAudio = useRef(null);
	const recodingBackground = useRef(null);

	const questionArray = useRef(null);

	const [rangerTextState, setRangerTextState] = useState("어서와~ 친구들!");
	const bunnyTellerRef = useRef(null);

	

	const startRecording = async () => {
		return new Promise((resolve, reject) => {
			//create new Media recorder instance using the stream
			setRangerTextState('크게 한번 말해볼까?');
			const media = new MediaRecorder(stream.current, { type: mimeType });
			alert(media)
			//set the MediaRecorder instance to the mediaRecorder ref
			mediaRecorder.current = media;
			//invokes the start method to start the recording process
			mediaRecorder.current.start();
			mediaRecorder.current.ondataavailable = (event) => {
				if (typeof event.data === 'undefined'){
					return;
				}
				if (event.data.size === 0){
					return;
				}
				audioChunks.current = [event.data]
			};
			resolve(1);
		});
	};
	const stopRecording = () => {
		return new Promise((resolve, reject) => {
			//stops the recording instance
			mediaRecorder.current.stop();
			mediaRecorder.current.onstop = () => {
				setRangerTextState('잘했어!');
				//creates a blob file from the audiochunks data
				const audioBlob = new Blob(audioChunks.current, { type: mimeType });
				//creates a playable URL from the blob file.
				const audioUrl = URL.createObjectURL(audioBlob);
				setAudio(audioUrl);
				audioChunks.current = [];
				resolve(audioBlob);
			};
		});
	};

	const startTestRecording = async () => {
		recordingAudio.current.style.display = 'none';
		lastBtnRef.current.style.opacity = 0.3;
		lastBtnRef.current.style.pointerEvents = 'none';
		await consolePlay();
		recodingBackground.current.style.display = 'block';
		setBtnBox(btnArray);
		setBtnBox((current) => [
			...current,
			{
				img_source: btn_fin,
				func: consolePlay,
				text: '권한허용',
				ref: firstBtnRef,
			},
		]);
		setBtnBox((current) => [
			...current,
			{
				img_source: btn_stop,
				func: stopTestRecording,
				text: '테스트',
				ref: playBtnRef,
			},
		]);
		setBtnBox((current) => [
			...current,
			{
				img_source: btn_forward,
				func: renderPage,
				text: '시작하기',
				ref: lastBtnRef,
				variable: 0,
			},
		]);

		await startRecording();
	};
	const stopTestRecording = async () => {
		recodingBackground.current.style.display = 'none';
		lastBtnRef.current.style.opacity = 1;
		lastBtnRef.current.style.pointerEvents = 'auto';
		setBtnBox(btnArray);
		setBtnBox((current) => [
			...current,
			{
				img_source: btn_fin,
				func: consolePlay,
				text: '권한허용',
				ref: firstBtnRef,
			},
		]);
		setBtnBox((current) => [
			...current,
			{
				img_source: btn_record,
				func: startTestRecording,
				text: '테스트',
				ref: playBtnRef,
			},
		]);
		setBtnBox((current) => [
			...current,
			{
				img_source: btn_forward,
				func: renderPage,
				text: '시작하기',
				ref: lastBtnRef,
				variable: 0,
			},
		]);
		await stopRecording();
	};
	const MainRecording = async (pageNum) => {
		if (mainRecordFuncStatus.current === 0) {
			
			recordingAudio.current.style.display = 'none';
			
			await consolePlay();
			playBtnRef.current.src = btn_stop;
			recodingBackground.current.style.display = 'block';

			await startRecording();
			mainRecordFuncStatus.current = 1;
		} else {
			console.log(audio);
			recodingBackground.current.style.display = 'none';

			mainRecordFuncStatus.current = 0;
			const uploadBlob = await stopRecording();

			const formData = new FormData();
			formData.append('audio', uploadBlob);
			console.log(uploadBlob);
			formData.append('ql_id',questionArray.current[pageNum].ql_id);
			const response = await fetch(`https://mall.purpleacademy.co.kr/api_v2/upload_from_recorder`, {
				method: 'post',
				body: formData,
			});
			const results = await response.json();
			console.log(results);


			recordingAudio.current.style.display = 'block';
			playBtnRef.current.src = btn_retry;
			lastBtnRef.current.style.opacity = 1;
			lastBtnRef.current.style.pointerEvents = 'auto';
			// questionArray.current[pageNum].record_file = results.filename;
		}
	};
	const getMicrophonePermission = async () => {
		if ('MediaRecorder' in window) {
			try {
				const streamData = await navigator.mediaDevices.getUserMedia({
					audio: true,
					video: false,
				});
				setPermission(true);
				stream.current = streamData;
				firstBtnRef.current.style.opacity = 0.3;
				firstBtnRef.current.style.pointerEvents = 'none';
				playBtnRef.current.style.opacity = 1;
				playBtnRef.current.style.pointerEvents = 'auto';
				lastBtnRef.current.style.opacity = 1;
				lastBtnRef.current.style.pointerEvents = 'auto';
			} catch (err) {
				playBtnRef.current.style.opacity = 0.3;
				playBtnRef.current.style.pointerEvents = 'none';
				lastBtnRef.current.style.opacity = 0.3;
				lastBtnRef.current.style.pointerEvents = 'none';
				alert('음성 기능을 사용할 수 없습니다.');
			}
		} else {
			alert('음성 기능을 사용할 수 없습니다.');
		}
	};
	const checkMicrophonePermission = async () => {
		try {
			const streamData = await navigator.mediaDevices.getUserMedia({
				audio: true,
				video: false,
			});
			setPermission(true);
			stream.current = streamData;
			firstBtnRef.current.style.opacity = 0.3;
			firstBtnRef.current.style.pointerEvents = 'none';
			playBtnRef.current.style.opacity = 1;
			playBtnRef.current.style.pointerEvents = 'auto';
			lastBtnRef.current.style.opacity = 1;
			lastBtnRef.current.style.pointerEvents = 'auto';
		} catch (err) {
			console.log(err);
			playBtnRef.current.style.opacity = 0.3;
			playBtnRef.current.style.pointerEvents = 'none';
			lastBtnRef.current.style.opacity = 0.3;
			lastBtnRef.current.style.pointerEvents = 'none';
			console.log('음성 기능을 사용할 수 없습니다.');
		}
	};

	function consolePlay() {
		return new Promise((resolve) => {
			setCoverFillColor('#000000B2');
			consoleCover.current.style.zIndex = 3;
			countdownNumRef.current.style.display = 'block';
			setCountdownNum(3);
			setRangerTextState('3!');
			setTimeout(() => {
				setCountdownNum(2);
				setRangerTextState('2!');
				setTimeout(() => {
					setCountdownNum(1);
					setRangerTextState('1!');
					setTimeout(() => {
						countdownNumRef.current.style.display = 'none';
						consoleCover.current.style.zIndex = 0;
						setCoverFillColor('none');
						resolve(1);
					}, 1000);
				}, 1000);
			}, 1000);
		});
	}

	function spinnerCtrl(bool, text = '데이터를 불러오는 중입니다.') {
		return new Promise((resolve) => {
			if (bool) {
				setSpinnerText(text);
				spinnerRef.current.style.display = 'block';
				resolve(1);
			} else {
				setSpinnerText('');
				spinnerRef.current.style.display = 'none';
				resolve(0);
			}
		});
	}

	function testPagectrl(bool) {
		return new Promise((resolve) => {
			if (bool) {
				pagnationRef.current.style.display = 'block';
				mainTextRef.current.style.display = 'block';
				resolve(1);
			} else {
				pagnationRef.current.style.display = 'none';
				setBtnBox(btnArray);
				setBtnBox((current) => [
					...current,
					{
						img_source: btn_fin,
						func: getMicrophonePermission,
						text: '권한허용',
						ref: firstBtnRef,
					},
				]);
				setBtnBox((current) => [
					...current,
					{
						img_source: btn_record,
						func: startTestRecording,
						text: '테스트',
						ref: playBtnRef,
					},
				]);
				setBtnBox((current) => [
					...current,
					{
						img_source: btn_forward,
						func: renderPage,
						text: '시작하기',
						ref: lastBtnRef,
						variable: 0,
					},
				]);
				resolve(0);
			}
		});
	}

	async function recordAfterPlay(pageNum) {
		if (mainRecordFuncStatus.current === 0) {
			firstBtnRef.current.style.opacity = 0.3;
			firstBtnRef.current.style.pointerEvents = "none";
			lastBtnRef.current.style.opacity = 0.3;
			lastBtnRef.current.style.pointerEvents = 'none';
			await consolePlay();
			musicRef.current.play();
			setRangerTextState('잘 들어보자!');
			playBtnRef.current.style.opacity = 0.3;
			playBtnRef.current.style.pointerEvents = 'none';
			musicRef.current.onended = async function () {
				setRangerTextState('이제 친구들 차례야!');
				playBtnRef.current.style.opacity = 1;
				playBtnRef.current.style.pointerEvents = 'auto';
				// console.log("onended???");
				MainRecording(pageNum);
			}
		} else {
			MainRecording(pageNum);
			firstBtnRef.current.style.opacity = 1;
			firstBtnRef.current.style.pointerEvents = 'auto';
			lastBtnRef.current.style.opacity = 1;
			lastBtnRef.current.style.pointerEvents = 'auto';
		}
	}
	async function recordNoPlay(pageNum) {
		if (mainRecordFuncStatus.current === 0) {
			setRangerTextState('준비!');
			firstBtnRef.current.style.opacity = 0.3;
			firstBtnRef.current.style.pointerEvents = 'none';
			lastBtnRef.current.style.opacity = 0.3;
			lastBtnRef.current.style.pointerEvents = 'none';
			MainRecording(pageNum);
		} else {
			MainRecording(pageNum);
			firstBtnRef.current.style.opacity = 1;
			firstBtnRef.current.style.pointerEvents = 'auto';
			lastBtnRef.current.style.opacity = 1;
			lastBtnRef.current.style.pointerEvents = 'auto';
		}
	}
	async function retryAfterPlay(pageNum) {
		if (mainRecordFuncStatus.current === 0) {
			firstBtnRef.current.style.opacity = 0.3;
			firstBtnRef.current.style.pointerEvents = 'none';
			lastBtnRef.current.style.opacity = 0.3;
			lastBtnRef.current.style.pointerEvents = 'none';
			// request('del_file', { filename: questionArray.current[pageNum].record_file });
			await consolePlay();
			setRangerTextState('잘 들어보자!');
			// musicRef.current.play();
			playBtnRef.current.style.opacity = 0.3;
			playBtnRef.current.style.pointerEvents = 'none';
			musicRef.current.onended = async function () {
				setRangerTextState('이제 친구들 차례야!');
				playBtnRef.current.style.opacity = 1;
				playBtnRef.current.style.pointerEvents = 'auto';
				MainRecording(pageNum);
			};
		} else {
			MainRecording(pageNum);
			firstBtnRef.current.style.opacity = 1;
			firstBtnRef.current.style.pointerEvents = 'auto';
			lastBtnRef.current.style.opacity = 1;
			lastBtnRef.current.style.pointerEvents = 'auto';
		}
	}
	async function retryNoPlay(pageNum) {
		if (mainRecordFuncStatus.current === 0) {
			firstBtnRef.current.style.opacity = 0.3;
			firstBtnRef.current.style.pointerEvents = 'none';
			lastBtnRef.current.style.opacity = 0.3;
			lastBtnRef.current.style.pointerEvents = 'none';
			request('del_file', {
				filename: questionArray.current[pageNum].record_file,
			});
			// await consolePlay();
			MainRecording(pageNum);
		} else {
			MainRecording(pageNum);
			firstBtnRef.current.style.opacity = 1;
			firstBtnRef.current.style.pointerEvents = 'auto';
			lastBtnRef.current.style.opacity = 1;
			lastBtnRef.current.style.pointerEvents = 'auto';
		}
	}

	async function finishTest(){
		
		recordingAudio.current.style.display = 'none';
		window.location.replace("comments")
	}

	async function submitWeekLog(){
		recordingAudio.current.style.display = 'none';
		pagnationRef.current.style.display = 'none';
		mainTextRef.current.style.display = 'none';
		btnBoxRef.current.style.display = 'none';
		await spinnerCtrl(1);
		const submitResult = await request(`update_week_log`, {
			week_log_id: questionArray.current[0].week_log_id,
		});
		await spinnerCtrl(0);
		mainTextRef.current.style.display = 'block';
		setMainText("제출이 완료되었습니다.")
		setSubText('홈페이지 계획표 인증을 잊지마세요!');
		setRangerTextState("다음주에 또 만나자!")

		btnBoxRef.current.style.display = 'flex';
		console.log(submitResult);
		setBtnBox(btnArray);
		setBtnBox((current) => [
			...current,
			{
				img_source: btn_forward,
				func: finishTest,
				text: '코멘트 확인하기',
				ref: firstBtnRef,
			},
		]);
	}

	function renderPage(pageNum) {
		if (pageNum === -1) {
			recordingAudio.current.style.display = 'none';
			testPagectrl(0);
			setPagnationBox(pagnationArray);
			pagnationRef.current.style.display = 'none';
			setMainText('마이크 사용 권한을 허용하고, 음성을 테스트해주세요.');
			setRangerTextState('마이크 테스트~ 아~');
			firstBtnRef.current.style.opacity = 0.3;
			firstBtnRef.current.style.pointerEvents = 'none';
			lastBtnRef.current.style.opacity = 1;
			lastBtnRef.current.style.pointerEvents = 'auto';
			return;
		}
		if(pageNum===0){
			setRangerTextState('자~ 준비!');
		}

		const questionState = questionArray.current;
		const thisPage = questionState[pageNum];

		recordingAudio.current.style.display = 'none';
		
		firstBtnRef.current.style.opacity = 1;
		firstBtnRef.current.style.pointerEvents = 'auto';

		setBtnBox(btnArray);
		setPagnationBox(pagnationArray);
		for (let i = 0; i < questionState.length; i++) {
			if (i === pageNum) {
				setPagnationBox((current) => [
					...current,
					{
						class_name: 'console-page console-page-selected',
						value: pageNum + 1,
					},
				]);
			} else {
				setPagnationBox((current) => [
					...current,
					{ class_name: 'console-page', value: '' },
				]);
			}
		}
		pagnationRef.current.style.display = 'flex';

		setMainText(thisPage.question);

		setMusic(
			'https://ranger.purpleacademy.co.kr/api/sound/' +
				thisPage.sound_file
		);

		// type , status, 끝 페이지 인지
		setBtnBox((current) => [
			...current,
			{
				img_source: btn_back,
				func: renderPage,
				text: '뒤로가기',
				variable: pageNum - 1,
				ref: firstBtnRef,
			},
		]);
		console.log(thisPage);
		if(isNaN(thisPage.record_file)){
			lastBtnRef.current.style.opacity = 1;
			lastBtnRef.current.style.pointerEvents = 'auto';
			setAudio('https://media.purpleacademy.co.kr/api_v2/convert/'+thisPage.record_file);
			recordingAudio.current.style.display = 'block';
			recordingAudio.current.pause()
			if (thisPage.type === 0) {
				setBtnBox((current) => [
					...current,
					{
						img_source: btn_retry,
						func: retryAfterPlay,
						variable: pageNum,
						text: '다시하기',
						ref: playBtnRef,
					},
				]);
			} else {
				setBtnBox((current) => [
					...current,
					{
						img_source: btn_retry,
						func: retryNoPlay,
						variable: pageNum,
						text: '다시하기',
						ref: playBtnRef,
					},
				]);
			}
		}else{
			if (thisPage.type === 0) {
				playBtnRef.current.src = btn_play;
				setBtnBox((current) => [
					...current,
					{
						img_source: btn_play,
						func: recordAfterPlay,
						variable: pageNum,
						text: '시작하기',
						ref: playBtnRef,
					},
				]);
			} else {
				playBtnRef.current.src = btn_record;
				setBtnBox((current) => [
					...current,
					{
						img_source: btn_record,
						func: recordNoPlay,
						variable: pageNum,
						text: '시작하기',
						ref: playBtnRef,
					},
				]);
			}

			lastBtnRef.current.style.opacity = 0.3;
			lastBtnRef.current.style.pointerEvents = 'none';
		}

		if (pageNum === questionState.length - 1) {
			setBtnBox((current) => [
				...current,
				{
					img_source: btn_fin,
					func: submitWeekLog,
					text: '제출하기',
					variable: pageNum + 1,
					ref: lastBtnRef,
				},
			]);
		} else {
			setBtnBox((current) => [
				...current,
				{
					img_source: btn_forward,
					func: renderPage,
					text: '다음으로',
					variable: pageNum + 1,
					ref: lastBtnRef,
				},
			]);
		}
	}

	const weekcheck = async () => {
		await testPagectrl(0);

		await spinnerCtrl(1);

		const result = {
			status:4,
		}	
			//await request('weekcheck', { token: token });
		setWeekLogId(result.week_log_id);
		console.log(result);
		if (result.status === 0) {
			setRangerTextState("다음주에 또 만나자!");
			setMainText('솔루션을 이미 진행했어요!');
			setSubText('홈페이지 계획표 인증을 잊지마세요!');
			await spinnerCtrl(0);
			mainTextRef.current.style.display = 'block';
			btnBoxRef.current.style.display = 'flex';
			setBtnBox(btnArray);
			setBtnBox((current) => [
				...current,
				{
					img_source: btn_forward,
					func: finishTest,
					text: '코멘트 확인하기',
					ref: firstBtnRef,
				},
			]);
		} else if (result.status === -1) {
			setMainText('솔루션 대상이 아닙니다.');
			await spinnerCtrl(0);
			mainTextRef.current.style.display = 'block';
			btnBoxRef.current.style.display = 'none';
		} else if( result.status ===2){
			setRangerTextState('수요일에 만나자!');
			setMainText('솔루션 가능기간이 아닙니다. (월, 화)');
			await spinnerCtrl(0);
			mainTextRef.current.style.display = 'block';
			btnBoxRef.current.style.display = 'flex';
			setBtnBox(btnArray);
			setBtnBox((current) => [
				...current,
				{
					img_source: btn_forward,
					func: finishTest,
					text: '코멘트 확인하기',
					ref: firstBtnRef,
				},
			]);
		}else {
			checkMicrophonePermission();
			await spinnerCtrl(0);
			setMainText('마이크 사용 권한을 허용하고, 음성을 테스트해주세요.');
			mainTextRef.current.style.display = 'block';
			// let get_test_questions = await request('get_test_question', {
			// 	token: token,
			// 	week_log_id: result.week_log_id,
			// });
			let get_test_questions = [
				{
					"ql_id": 31979,
					"register_no": "P111111",
					"week_log_id": 7601,
					"week": 197,
					"set": "B",
					"question_id": 1381,
					"record_file": null,
					"stt_text": null,
					"status": 0,
					"question": "I am a truck.",
					"sound_file": "GRN_3_5.mp3",
					"code": "GRN_3_5",
					"type": 1
				},
				{
					"ql_id": 31980,
					"register_no": "P111111",
					"week_log_id": 7601,
					"week": 197,
					"set": "B",
					"question_id": 1382,
					"record_file": null,
					"stt_text": null,
					"status": 0,
					"question": "My best friend is a duck!",
					"sound_file": "GRN_3_6.mp3",
					"code": "GRN_3_6",
					"type": 1
				},
				{
					"ql_id": 31980,
					"register_no": "P111111",
					"week_log_id": 7601,
					"week": 197,
					"set": "B",
					"question_id": 1382,
					"record_file": null,
					"stt_text": null,
					"status": 0,
					"question": "My best friend is a duck!",
					"sound_file": "GRN_3_6.mp3",
					"code": "GRN_3_6",
					"type": 1
				},
				{
					"ql_id": 31980,
					"register_no": "P111111",
					"week_log_id": 7601,
					"week": 197,
					"set": "B",
					"question_id": 1382,
					"record_file": null,
					"stt_text": null,
					"status": 0,
					"question": "My best friend is a duck!",
					"sound_file": "GRN_3_6.mp3",
					"code": "GRN_3_6",
					"type": 1
				}
			]
			console.log(get_test_questions);
			if (get_test_questions.status) {
				get_test_questions = await request('get_test_question', {
					token: token,
					week_log_id: result.week_log_id,
				});
			}
			questionArray.current = get_test_questions;
		}
	};
	useEffect(() => {
		weekcheck();
	}, []);

	return (
		<div className="App">
			<div className="full_wrapper aitest-scroll">
				<Header />
				<main className="aitest-main-body">
					<div className="music-container">
						<audio src={music} ref={musicRef} volume="1"></audio>
					</div>
					<div className="console-wrapper">
						<svg
							className="icon-close"
							onClick={() => {
								window.location.replace('main');
							}}
							xmlns="http://www.w3.org/2000/svg"
							width="68"
							height="68"
							viewBox="0 0 68 68"
							fill="none"
						>
							<circle cx="36" cy="36" r="32" fill="#313131" />
							<circle cx="32" cy="32" r="32" fill="#565656" />
							<rect
								x="41.9048"
								y="16"
								width="8.61994"
								height="36.6347"
								rx="4.30997"
								transform="rotate(45 41.9048 16)"
								fill="white"
							/>
							<rect
								x="16"
								y="22.0951"
								width="8.61994"
								height="36.6347"
								rx="4.30997"
								transform="rotate(-45 16 22.0951)"
								fill="white"
							/>
						</svg>
						<div className="spinner-wrapper" ref={spinnerRef}>
							<div id="spinner"></div>
							<div>{spinnerText}</div>
						</div>
						<div className="countdown-number" ref={countdownNumRef}>
							{countdownNum}
						</div>
						<svg
							className="console-cover"
							ref={consoleCover}
							xmlns="http://www.w3.org/2000/svg"
							width="100%"
							height="100%"
							viewBox="0 0 1168 672"
							preserveAspectRatio="none"
							fill="none"
						>
							<path
								d="M27.7129 157.525C39.9856 75.0529 110.796 14 194.177 14H973.823C1057.2 14 1128.01 75.0529 1140.29 157.525C1157.9 275.857 1157.9 396.143 1140.29 514.475C1128.01 596.947 1057.2 658 973.823 658H194.177C110.796 658 39.9856 596.947 27.7129 514.475C10.104 396.143 10.104 275.857 27.7129 157.525Z"
								fill={coverFillColor}
								stroke="#E6D7E8"
								strokeWidth="28"
							/>
						</svg>
						<div className="console-pagnation" ref={pagnationRef}>
							{pagnationBox.map((element, index) => {
								return (
									<div className={element.class_name} key={index}>
										{element.value}
									</div>
								);
							})}
						</div>
						<div className="console-msg-box" ref={mainTextRef}>
							{mainText}
							<br/>
							<span className='aitest-sub-text'>{subText}</span>
						</div>

						<img
							className="recording-background"
							src={btn_highlight}
							ref={recodingBackground}
							alt=""
						/>
						<div className="console-btn-box" ref={btnBoxRef}>
							{btnBox.map((element, index) => {
								return (
									<div key={index} className="btn-set">
										<div className="btn-img-wrapper">
											<img
												src={element.img_source}
												alt=""
												ref={element.ref}
												onClick={() => element.func(element.variable)}
											/>
										</div>
										<div className="btn-name">{element.text}</div>
									</div>
								);
							})}
						</div>
						<div className="audio-container">
							<audio
								className="record-audio"
								src={audio}
								autoPlay
								controls
								volume="1"
								ref={recordingAudio}
							></audio>
						</div>
						<svg
							className="console-box"
							xmlns="http://www.w3.org/2000/svg"
							width="100%"
							height="100%"
							preserveAspectRatio="none"
							viewBox="0 0 1168 672"
							fill="none"
						>
							<path
								d="M27.7129 157.525C39.9856 75.0529 110.796 14 194.177 14H973.823C1057.2 14 1128.01 75.0529 1140.29 157.525C1157.9 275.857 1157.9 396.143 1140.29 514.475C1128.01 596.947 1057.2 658 973.823 658H194.177C110.796 658 39.9856 596.947 27.7129 514.475C10.104 396.143 10.104 275.857 27.7129 157.525Z"
								fill="white"
								stroke="#E6D7E8"
								strokeWidth="0"
							/>
						</svg>
						<div className="detail-ranger-wrapper">
							<div className="comment-ranger-text">{rangerTextState}</div>
							<img
								className="comment-readingranger"
								alt=""
								height="220px"
								ref={bunnyTellerRef}
								src={ranger_original}
							/>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}

export default AItest;
