import logo from './logo.svg';
import '../../App.css';
import './FAQ.css';
import { request, LocalStorage } from '../../App';
import Header from '../Header/Header';

import btn_back from '../../images/btn_back.svg';
import btn_forward from '../../images/btn_forward.svg';
import btn_highlight from '../../images/btn_highlight.svg';
import btn_play from '../../images/btn_play.svg';
import btn_record from '../../images/btn_record.svg';
import btn_retry from '../../images/btn_retry.svg';
import btn_stop from '../../images/btn_stop.svg';
import btn_fin from '../../images/btn_fin.png';
import { useState, useRef, useEffect } from 'react';
import { render } from '@testing-library/react';
import { type } from '@testing-library/user-event/dist/type';
function FAQ() {
	const [token, setToken] = LocalStorage('token', '');
	

	const weekcheck = async () => {
		
		const result = await request('weekcheck', { token: token });
		
	};
	useEffect(() => {
		weekcheck();
	}, []);

	return (
		<div className="App">
			<div className="full_wrapper">
				<Header />
				<main>
				</main>
			</div>
		</div>
	);
}

export default FAQ;
